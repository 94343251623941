<template>
  <div class="accent" v-if="!isExternalAssessor">
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
            | <span class="title">Summary</span>
          </h1>
        </v-col>
      </v-row>

      <v-container>
        <v-row>
          <v-col class="mx-auto" cols="12" md="12">
            <v-card>
              <div class="tw-flex tw-justify-center tw-items-center tw-p-6">
                <div class="tw-w-full md:tw-w-1/2">
                  <app-welcome-wordings></app-welcome-wordings>
                </div>

                <div class="tw-relative tw-w-80 tw-hidden md:tw-block">
                  <img
                    src="@/assets/img/Group.png"
                    alt="Group"
                    class="tw-absolute tw-top-20 tw-left-10"
                  />

                  <img
                    src="@/assets/img/Face.png"
                    alt="Face"
                    class="tw-mx-auto"
                  />
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="mt-8">
        <v-row>
          <v-col class="mx-auto" cols="12" md="12">
            <app-assessment-centre-updates
              :summary="assessmentCentreSummary"
              :isLoading="isFetchingAssessmentCentreSummary"
            ></app-assessment-centre-updates>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="mt-8">
        <v-row>
          <v-col class="mx-auto" cols="12" md="12">
            <app-garage-updates
              :summary="garageSummary"
              :isLoading="isFetchingGarageSummary"
            ></app-garage-updates>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>

  <div v-else>
    <app-external-assessor-dashboard
      :summary="extAssessorSummary"
      :isLoading="isFetchingExtAssessorSummary"
    ></app-external-assessor-dashboard>
  </div>
</template>

<script>
import axios from 'axios'
import { viewPermissions } from '@/utils/roles'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  components: {
    appAssessmentCentreUpdates: () =>
      import('@/components/dashboard/AssessmentCentreUpdates'),
    appGarageUpdates: () => import('@/components/dashboard/GarageUpdates'),
    appExternalAssessorDashboard: () =>
      import('@/components/dashboard/ExternalAssessorDashboard'),
    appWelcomeWordings: () => import('@/components/dashboard/Welcome'),
  },
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    isFetchingAssessmentCentreSummary: false,
    assessmentCentreSummary: {
      bookedVehicles: null,
      supplementaryReceived: null,
      submittedBids: null,
      approvedBids: null,
      writeOf: null,
      belowExcess: null,
      cashInLieu: null,
      declined: null,
      undocumented: null,
      underInvestigation: null,
      checkedOut: null,
    },
    isFetchingGarageSummary: false,
    garageSummary: {
      total_vehicles: null,
      repairs_count: null,
      released_count: null,
    },
    isFetchingExtAssessorSummary: false,
    extAssessorSummary: {
      bidForms: 0,
      reinspections: 0,
      supplementaries: 0,
    },
    refreshTimer: null,
  }),
  computed: {
    isExternalAssessor() {
      return !this.viewPermissions([
        'internalAssessor',
        'technician',
        'admin',
        'claimOfficer',
        'sectionHead',
      ])
    },

    ...mapState({
      user: (state) => state.auth.user,
    }),
    greeting() {
      const currentHour = moment().hour()

      if (currentHour >= 0 && currentHour < 12) {
        return 'Good Morning,'
      } else if (currentHour >= 12 && currentHour < 16) {
        return 'Good Afternoon,'
      } else {
        return 'Good Evening,'
      }
    },
  },
  methods: {
    viewPermissions,
    async fetchAssessmentCentreSummary() {
      this.isFetchingAssessmentCentreSummary = true

      await axios
        .get('motor-assessment/api/dashboard/live/count')
        .then(({ data }) => {
          if (data.success) {
            this.assessmentCentreSummary = { ...data.data }
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message:
              err.message ?? 'Failed to load assessment centre summary count',
            variant: 'error',
          })
        })
        .finally(() => (this.isFetchingAssessmentCentreSummary = false))
    },
    async fetchGarageSummary() {
      this.isFetchingGarageSummary = true

      await axios
        .get('/motor-assessment/api/dashboard/garage/count')
        .then(({ data }) => {
          if (data.success) {
            this.garageSummary = { ...data.data }
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to load garage summary count',
            variant: 'error',
          })
        })
        .finally(() => (this.isFetchingGarageSummary = false))
    },

    async fetchExtAssessorSummary() {
      this.isFetchingExtAssessorSummary = true

      await axios
        .get(
          `/motor-assessment/api/dashboard/external-assessor/count/${this.$store.state.auth.user.sub}`
        )
        .then(({ data }) => {
          if (data.success) {
            this.extAssessorSummary = data.data
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: 'Failed to retrieve summary count',
              variant: 'error',
            })
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to retrieve summary count',
            variant: 'error',
          })
        })
        .finally(() => (this.isFetchingExtAssessorSummary = false))
    },
    startAutoRefresh() {
      const refreshInterval = 5 * 60 * 1000

      if (this.refreshTimer) clearInterval(this.refreshTimer)

      this.refreshTimer = setInterval(() => {
        if (!this.isExternalAssessor) {
          this.fetchGarageSummary()
          this.fetchAssessmentCentreSummary()
        } else {
          this.fetchExtAssessorSummary()
        }
      }, refreshInterval)
    },
    stopAutoRefresh() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer)
        this.refreshTimer = null
      }
    },
  },
  beforeMount() {
    if (!this.isExternalAssessor) {
      this.fetchGarageSummary()
      this.fetchAssessmentCentreSummary()
    } else {
      this.fetchExtAssessorSummary()
    }
    this.startAutoRefresh()
  },
  beforeDestroy() {
    this.stopAutoRefresh()
  },
}
</script>
